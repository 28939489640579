import React, { Component } from "react"
import { Link } from "gatsby"
import KitchenRemodelingImage from '../../images/kitchen.jpeg'
import BathroomRemodelingImage from '../../images/bathroom.jpeg'
import WindowsImage from '../../images/windows.jpeg'
import FireWaterImage from '../../images/fire-water.jpeg'
// import PaintingImage from '../../images/painting.jpeg'
// import FlooringImage from '../../images/flooring.jpeg'
import SidingImage from '../../images/siding.jpeg'
// import NewConstructionImage from '../../images/new-construction.jpeg'
import Kitchen from "../../icons/kitchen"
import Bathroom from '../../icons/bathroom'
import Windows from '../../icons/windows'
// import Flooring from '../../icons/flooring'
// import Construction from '../../icons/construction'
// import Painting from '../../icons/painting'
import FireWater from '../../icons/fire-water'
import Siding from '../../icons/siding'

export class ServicesSectionServicePg extends Component {
    render() {
        let hideBathroomService = false
        let hideFireWaterService = false
        let hideFlooringService = false
        let hideKitchenService = false
        let hideNewConstructionService = false
        let hidePaintingService = false
        let hideWindowsService = false
        let hideSidingService = false


        if (this.props.data.serviceName.includes('Kitchen Remodel')) {
            hideKitchenService = true
        }
        else if (this.props.data.serviceName.includes('Bathroom Remodel')) {
            hideBathroomService = true
        }
        else if (this.props.data.serviceName.includes('Fire')) {
            hideFireWaterService = true
        }
        else if (this.props.data.serviceName.includes('Flooring')) {
            hideFlooringService = true
        }
        else if (this.props.data.serviceName.includes('New Construction')) {
            hideNewConstructionService = true
        }
        else if (this.props.data.serviceName.includes('Painting')) {
            hidePaintingService = true
        }
        else if (this.props.data.serviceName.includes('Window')) {
            hideWindowsService = true
        }
        else if (this.props.data.serviceName.includes('Siding')) {
            hideSidingService = true
        }

        return (
            <section id='otherservices' className="services center py-12 md:py-16 bg-gray">
                <div className="container mx-auto">
                    <div className="flex flex-wrap ">
                        <div className="md:w-full pr-4 pl-4">
                            <div className="section-subtitle">What We Do</div>
                            <div className="section-title">Other <span>Services</span></div>
                        </div>
                    </div>
                    <div className="flex flex-wrap">
                        <div className="md:w-full pr-4 pl-4 owl-carousel owl-theme grid md:grid-cols-2 lg:grid-cols-3 gap-x-7">
                        <div className={`${hideKitchenService ? 'hidden' : 'item mb-30'}`}>
                                <div className="service-img">
                                    <div className="img"> <img src={KitchenRemodelingImage} loading='eager' title='View Our Kitchen Remodel Services' alt='View Our Kitchen Remodel Services' /> </div>
                                </div>
                                <div className="cont">
                                    <div className="service-icon"> <i className="mx-auto flex justify-center"><Kitchen /></i> </div>
                                    <h5><Link to="/kitchen-remodel" title='View Our Kitchen Remodel Services' alt='View Our Kitchen Remodel Services'>Kitchen Remodel</Link></h5>
                                    <p>Transform your cooking space with our professional kitchen remodel services, combining functionality and style for a modern, efficient kitchen you'll love.</p>
                                    <Link to="/kitchen-remodel" className="link-btn" tabIndex="0" title='View Our Kitchen Remodel Services' alt='View Our Kitchen Remodel Services'>View service</Link>
                                </div>
                            </div>
                            <div className={`${hideBathroomService ? 'hidden' : 'item mb-30'}`}>
                                <div className="service-img">
                                    <div className="img"> <img src={BathroomRemodelingImage} loading='eager' title='View Our Bathroom Remodel Services' alt='View Our Bathroom Remodel Services' /> </div>
                                </div>
                                <div className="cont">
                                    <div className="service-icon"> <i className="mx-auto flex justify-center"><Bathroom /></i> </div>
                                    <h5><Link to="/bathroom-remodel" title='View Our Bathroom Remodel Services' alt='View Our Bathroom Remodel Services'>Bathroom Remodel</Link></h5>
                                    <p>Transform your bathroom into a luxurious retreat with our expert remodeling services, where quality craftsmanship and attention to detail are our top priorities.</p>
                                    <Link to="/bathroom-remodel" className="link-btn" tabIndex="0" title='View Our Bathroom Remodel Services' alt='View Our Bathroom Remodel Services'>View service</Link>
                                </div>
                            </div>
                            <div className={`${hideFireWaterService ? 'hidden' : 'item mb-30'}`}>
                                <div className="service-img">
                                    <div className="img"> <img src={FireWaterImage} loading='eager' title='View Our Fire & Water Restoration Services' alt='View Our Fire & Water Restoration Services' /> </div>
                                </div>
                                <div className="cont">
                                    <div className="service-icon"> <i className="mx-auto flex justify-center"><FireWater /></i> </div>
                                    <h5><Link to="/fire-water-restoration" title='View Our Fire & Water Restoration Services' alt='View Our Fire & Water Restoration Services'>Fire & Water Restoration</Link></h5>
                                    <p>Restore your home swiftly and professionally with our comprehensive fire and water restoration services, ensuring peace of mind during stressful times.</p>
                                    <Link to="/fire-water-restoration" className="link-btn" tabIndex="0" title='View Our Fire & Water Restoration Services' alt='View Our Fire & Water Restoration Services'>View service</Link>
                                </div>
                            </div>
                            {/* <div className={`${hideFlooringService ? 'hidden' : 'item mb-30'}`}>
                                <div className="service-img">
                                    <div className="img"> <img src={FlooringImage} loading='eager' title='View Our Flooring Services' alt='View Our Flooring Services' /> </div>
                                </div>
                                <div className="cont">
                                    <div className="service-icon"> <i className="mx-auto flex justify-center"><Flooring /></i></div>
                                    <h5><Link to="/flooring" title='View Our Flooring Services' alt='View Our Flooring Services'>Flooring</Link></h5>
                                    <p>Upgrade your home with our expert flooring services, providing high-quality installation and beautiful results that enhance any space.</p>
                                    <Link to="/flooring" className="link-btn" tabIndex="0" title='View Our Flooring Services' alt='View Our Flooring Services'>View service</Link>
                                </div>
                            </div>
                            <div className={`${hideNewConstructionService ? 'hidden' : 'item mb-30'}`}>
                                <div className="service-img">
                                    <div className="img"> <img src={NewConstructionImage} loading='eager' title='View Our New Construction Services' alt='View Our New Construction Services' /> </div>
                                </div>
                                <div className="cont">
                                    <div className="service-icon"> <i className="mx-auto flex justify-center"><Construction /></i></div>
                                    <h5><Link to="/new-construction" title='View Our New Construction Services' alt='View Our New Construction Services'>New Construction</Link></h5>
                                    <p>Build your dream home from the ground up with our expert new construction services, ensuring quality craftsmanship and attention to detail at every step.</p>
                                    <Link to="/new-construction" className="link-btn" tabIndex="0" title='View Our New Construction Services' alt='View Our New Construction Services'>View service</Link>
                                </div>
                            </div>
                            <div className={`${hidePaintingService ? 'hidden' : 'item mb-30'}`}>
                                <div className="service-img">
                                    <div className="img"> <img src={PaintingImage} loading='eager' title='View Our Painting Services' alt='View Our Painting Services' /> </div>
                                </div>
                                <div className="cont">
                                    <div className="service-icon"> <i className="mx-auto flex justify-center"><Painting /></i></div>
                                    <h5><Link to="/painting" title='View Our Painting Services' alt='View Our Painting Services'>Painting</Link></h5>
                                    <p>Revitalize your home with our professional painting services, offering meticulous attention to detail and high-quality finishes for both interior and exterior projects.</p>
                                    <Link to="/painting" className="link-btn" tabIndex="0" title='View Our Painting Services' alt='View Our Painting Services'>View service</Link>
                                </div>
                            </div> */}
                            <div className={`${hideSidingService ? 'hidden' : 'item mb-30'}`}>
                                <div className="service-img">
                                    <div className="img"> <img src={SidingImage} loading='eager' title='View Our Siding Services' alt='View Our Siding Services' /> </div>
                                </div>
                                <div className="cont">
                                    <div className="service-icon"> <i className="mx-auto flex justify-center"><Siding /></i></div>
                                    <h5><Link to="/siding" title='View Our Siding Services' alt='View Our Siding Services'>Siding</Link></h5>
                                    <p>Enhance your home's curb appeal and durability with our expert siding installation and repair services, using top-quality materials for a lasting finish.</p>
                                    <Link to="/siding" className="link-btn" tabIndex="0" title='View Our Siding Services' alt='View Our Siding Services'>View service</Link>
                                </div>
                            </div>
                            <div className={`${hideWindowsService ? 'hidden' : 'item mb-30'}`}>
                                <div className="service-img">
                                    <div className="img"> <img src={WindowsImage} loading='eager' title='View Our Window Replacement Services' alt='View Our Window Replacement Services' /> </div>
                                </div>
                                <div className="cont">
                                    <div className="service-icon"> <i className="mx-auto flex justify-center"><Windows /></i></div>
                                    <h5><Link to="/window-replacement" title='View Our Window Replacement Services' alt='View Our Window Replacement Services'>Window Replacement</Link></h5>
                                    <p>Upgrade your home's energy efficiency and aesthetics with our professional window replacement services, offering a variety of styles and high-quality materials.</p>
                                    <Link to="/window-replacement" className="link-btn" tabIndex="0" title='View Our Window Replacement Services' alt='View Our Window Replacement Services'>View service</Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        )
    }
}